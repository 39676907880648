import {controller, target} from '@github/catalyst'
import {announceFromElement} from '../aria-live'

@controller
class RunnerScalingElement extends HTMLElement {
  // Min runner input
  @target minInput: HTMLInputElement
  @target minGroup: HTMLElement
  @target minErrorMessage: HTMLElement
  @target invalidMinInputMessage: HTMLElement
  @target maxTooHighErrorMessage: HTMLElement
  @target minNote: HTMLElement

  // Max runner input
  @target maxInput: HTMLInputElement
  @target maxGroup: HTMLElement
  @target maxErrorMessage: HTMLElement
  @target invalidMaxInputMessage: HTMLElement
  @target maxNote: HTMLElement

  validateMin() {
    this.resetMinState()

    if (!this.minInput.value) return

    const value: number = +this.minInput.value

    // Unrecognized minimum input
    if (!value && value !== 0) {
      this.minGroup.classList.add('errored')
      this.invalidMinInputMessage.hidden = false
      announceFromElement(this.minNote)
      return
    }

    // Minimum input is too low
    if (value < 0) {
      this.minGroup.classList.add('errored')
      this.minErrorMessage.hidden = false
      announceFromElement(this.minNote)
      return
    }
  }

  validateMax() {
    this.resetMaxState()

    if (!this.maxInput.value) return

    const maxValue: number = +this.maxInput.value

    // Unrecognized maximum input
    if (!maxValue) {
      this.maxGroup.classList.add('errored')
      this.invalidMaxInputMessage.hidden = false
      announceFromElement(this.maxNote)
      return
    }

    const minValue: number = +this.minInput.value

    // Maximum input is too low
    if (maxValue < 0 || minValue > maxValue) {
      this.maxGroup.classList.add('errored')
      this.maxErrorMessage.hidden = false
      announceFromElement(this.maxNote)
      return
    }

    // Maximum input is too high
    if (maxValue > 99) {
      this.maxGroup.classList.add('errored')
      this.maxTooHighErrorMessage.hidden = false
      announceFromElement(this.maxNote)
      return
    }
  }

  resetMinState() {
    this.minErrorMessage.hidden = true
    this.invalidMinInputMessage.hidden = true
    this.minGroup.classList.remove('errored')
  }

  resetMaxState() {
    this.maxErrorMessage.hidden = true
    this.invalidMaxInputMessage.hidden = true
    this.maxGroup.classList.remove('errored')
  }
}

@controller
class RunnerImageElement extends HTMLElement {
  @target customSelect: HTMLInputElement
  @target uriInput: HTMLInputElement

  toggleRequiredInput() {
    if (this.customSelect.checked) {
      this.uriInput.required = true
    } else {
      this.uriInput.required = false
    }
  }
}
